@tailwind base;
@tailwind components;
@tailwind utilities;
@import './player.css';

.App {
  @apply text-center h-[100dvh] w-screen overflow-hidden text-light;
}

.fullscreen.landing {
  @apply grid w-full lg:pb-0 grid-cols-1 grid-rows-3 lg:grid-rows-1 lg:grid-cols-3;
  height: calc(100% - 2em);
}

.fullscreen.landing .player-wrap  {
  @apply absolute w-full top-0;
}
.fullscreen.landing .project-wrap {
  @apply relative z-10 overflow-hidden flex items-center flex-col justify-center gap-4;
}
.fullscreen.landing .project-wrap.active {@apply gap-0;}
.fullscreen.landing .project-wrap a {
  @apply flex justify-center gap-2;
}
.project-wrap > div {
  order: 2;
  position: relative;
}
.fullscreen.landing .project-wrap > section {
  @apply absolute z-0;
}
.fullscreen.landing .video-player {
  @apply object-cover w-full absolute z-0 top-0 z-0 h-full;
  max-height: 100%;
}
.fullscreen.landing .project-wrap,
.fullscreen.landing section {
  @apply h-full;
}

nav {
  @apply w-full flex flex-col justify-center sticky bottom-0 z-20 items-center mx-auto;
}
form {

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
}
form > div {
  @apply flex justify-center;
}
form input,
form input::placeholder {
  color: white;
}
form > div, form button {
  padding: .5rem;
}
form > div {
  border-bottom: 2px solid white;
}
form input {
  background-color: transparent;
  margin: 0px .5rem;
}
form button[type="submit"] {
  background: white;
  color: black;
  border-radius: 20px;
  margin: 0px .5rem;
  padding: .5rem 1.5rem;
  text-transform: capitalize;
}


.email-form .MuiInputBase-input, .email-form .MuiFormLabel-root {
  @apply text-light;
}
.email-form button {
  @apply w-full py-0 leading-tight;
  max-width: 100px;
}
.project-wrap > div.share-wrap {
  @apply absolute bottom-4 left-0 right-0 w-fit mx-auto;
}
.project-wrap h2 { pointer-events: none; }
.project-wrap a { cursor: pointer; }
.project-wrap h2 img { padding: 30px; max-height: 180px; }

.fullscreen.collective {
  @apply h-screen pb-32 overflow-scroll w-screen bg-dark z-30;
}
.fullscreen.collective h1, .fullscreen.collective h2, .fullscreen.collective h3, .fullscreen.collective h4 {
  @apply font-thin text-2xl pt-12 pb-4;
}
.fullscreen.collective h3 {
  @apply text-xl pt-4;
}
.fullscreen.collective p {
  @apply text-sm pb-2;
}
.fullscreen.collective a {
  @apply underline;
}
.fullscreen.collective li {
  @apply my-1;
}
#close-btn {
  @apply absolute z-40 left-4 top-0 mt-4;
}
.artist {
  @apply grid gap-4;
  grid-template-columns: .5fr 2fr;
}
.artist + .artist {@apply mt-6;}
@media all and (min-width: 1024px) {
  .artist {
    grid-template-columns: 1fr 2fr;
  }
}